import React, { useEffect, useState } from 'react';
import {
    Heading,
    Button,
    FormRow,
    Input,
    Select,
    SelectOption,
} from '@flixbus/honeycomb-react';
import { useBusinessRegions, useConcessionOwners } from "../Filters/hooks";

const INITIAL_FILTERS = {
    code: '',
    title: '',
    automaticDelays: '',
    paxRights: '',
    busArrived: '',
    concessionOwner: '',
    businessRegion: '',
};

export default function SettingsFilters({ translate, onFilter }) {
    const [filters, setFilters] = useState({ ...INITIAL_FILTERS });
    const [concessionOwners, getConcessionOwners] = useConcessionOwners();
    const [sortedConcessionOwners, setSortedConcessionOwners] = useState([])
    const [businessRegions, getBusinessRegions] = useBusinessRegions();

    useEffect(() => {
        getConcessionOwners();
        getBusinessRegions();
    }, [getConcessionOwners, getBusinessRegions])

    useEffect(() => {
        if (concessionOwners?.data) {
            const sorted = [...concessionOwners.data].sort((a, b) => {
                const titleA = a.title?.toLowerCase() || '';
                const titleB = b.title?.toLowerCase() || '';

                if (titleA < titleB) return -1;
                if (titleA > titleB) return 1;
                return 0;
            });

            setSortedConcessionOwners(sorted);
        }
    }, [concessionOwners]);


    const handleChange = (e, key) => {
        setFilters({ ...filters, [key]: e.target.value });
    };
    const handleSubmit = () => {
        onFilter(filters);
    };
    const handleRest = () => {
        setFilters({ ...INITIAL_FILTERS });
        onFilter(null);
    };

    return (
        <div className="settings-page__filters">
            <Heading size={3}>{translate('filters')}</Heading>
            <FormRow>
                <Input
                    name="code"
                    id="code"
                    label={translate('settings__code')}
                    value={filters.code}
                    onChange={(e) => handleChange(e, 'code')}
                />
            </FormRow>
            <FormRow>
                <Input
                    name="title"
                    id="title"
                    label={translate('settings__title')}
                    value={filters.title}
                    onChange={(e) => handleChange(e, 'title')}
                />
            </FormRow>
            {sortedConcessionOwners.length && (
                <FormRow>
                    <Select
                        id="concessionOwner"
                        placeholder=" "
                        label={translate('settings__concession_owner')}
                        onChange={(e) => handleChange(e, 'concessionOwner')}
                        value={filters.concessionOwner}
                    >
                        {sortedConcessionOwners.map(({ id, title }) => (
                            <SelectOption key={id} value={id}>{title}</SelectOption>
                        ))}
                    </Select>
                </FormRow>
            )}
            {Object.entries(businessRegions).length && (
                <FormRow>
                    <Select
                        id="businessRegion"
                        placeholder=" "
                        label={translate('settings__line_region')}
                        onChange={(e) => handleChange(e, 'businessRegion')}
                        value={filters.businessRegion}
                    >
                        {Object.entries(businessRegions).map(([id, name]) => (
                            <SelectOption key={id} value={id}>{name}</SelectOption>
                        ))}
                    </Select>
                </FormRow>
            )}
            <FormRow>
                <Select
                    id="automaticDelays"
                    label={translate('settings__automatic_delay')}
                    onChange={(e) => handleChange(e, 'automaticDelays')}
                    value={filters.automaticDelays}
                >
                    <SelectOption value={''}></SelectOption>
                    <SelectOption value={'Yes'}>{translate('yes')}</SelectOption>
                    <SelectOption value={'No'}>{translate('no')}</SelectOption>
                </Select>
            </FormRow>
            <FormRow>
                <Select
                    id="paxRights"
                    label={translate('pax_rights')}
                    onChange={(e) => handleChange(e, 'paxRights')}
                    value={filters.paxRights}
                >
                    <SelectOption value={''}></SelectOption>
                    <SelectOption value={'Yes'}>{translate('yes')}</SelectOption>
                    <SelectOption value={'No'}>{translate('no')}</SelectOption>
                </Select>
            </FormRow>
            <FormRow>
                <Select
                    id="busArrived"
                    label={translate('settings__bus_arrived')}
                    onChange={(e) => handleChange(e, 'busArrived')}
                    value={filters.busArrived}
                >
                    <SelectOption value={''}></SelectOption>
                    <SelectOption value={'Yes'}>{translate('yes')}</SelectOption>
                    <SelectOption value={'No'}>{translate('no')}</SelectOption>
                </Select>
            </FormRow>
            <FormRow>
                <Button
                    appearance="secondary"
                    onClick={handleSubmit}
                    aria-label="Submit"
                >
                    {translate('submit')}
                </Button>
                <Button
                    appearance="primary"
                    onClick={handleRest}
                    aria-label="Reset"
                >
                    {translate('reset')}
                </Button>
            </FormRow>
        </div>
    );
}
